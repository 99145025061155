.card h3{
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 10px;
}
.card p{
    margin: 0px;
    color: #000;
    font-size: 14px;
}
.card h3 a{
    display: inline-block;
    cursor: pointer;
}