.mainwapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    height: 100vh;
}
.menu{
    width: 322px;
}
.wapper{
    width: calc(100% - 322px);
    position: relative;
}
.heawapper-body{
    height: calc(100vh - 81px);
    overflow: auto;
    background: #F1F1F1;
    padding: 20px;
}