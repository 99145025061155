.xlm .modalBody_custom{
    width: 600px;
}
.xlm  .uploadBtn{
    margin-bottom: 20px;
}
.xlm  .form-group input{
    padding: 10px 15px;
    font-size: 14px;
    height: 45px;
}
.xlm .col-12{
    width: 100%;
    padding: 0px 15px;
}
.xlm .col-6{
    width: 50%;
    padding: 0px 15px;
}
.xlm .col-4{
    width: 33.33%;
    padding: 0px 15px;
}
.xlm .cancelBtn {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: 0.3s;
    background: #F42323;
    border: 1px solid #F42323;
}
.xlm .sucessBtn {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: 0.3s;
    background: #09CF11;
    border: 1px solid #09CF11;
    margin-left: 20px;
}

.note_pendding {
    background: rgb(0 219 9 / 10%);
    border: 1px solid #fcd64d;
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    color: #fba300;
}
.uploadePic_bank{
    width: 250px;
    height: 250px;
    overflow: hidden;
    border-radius: 5%;
    border: 2px solid #09CF11;
    margin-bottom: 20px;
}
.uploadePic_bank img{width: 100%;
    height: 100%;
    object-fit: cover;}
.photoUpload_bank{
    display: flex;
    align-items: center;
}
.outerBorder{
    border: 1px solid #ccc;  border-radius: 10px;
}