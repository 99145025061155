.ChangePassword{
    .form-group{
        position: relative;
        padding-bottom: 30px;
        label{
            display: block;
            font-size: 14px;
            color: #000;
            font-weight: 600;
            margin-bottom: 5px;
        }
        input{
            height: 45px;
            padding-left: 15px;
      
        }
    }
    .cancelBtn{
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          padding: 10px 25px;
          border-radius: 10px;
          outline: 0 !important;
          cursor: pointer;
          transition: 0.3s;
          background: #F42323;
         border: 1px solid #F42323;
         &:hover{
            background: #fff;
            color: #F42323;
         }
    }
    .sucessBtn{
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 10px;
        outline: 0 !important;
        cursor: pointer;
        transition: 0.3s;
        background: #09CF11;
        border: 1px solid #09CF11;
        margin-left: 20px;
        &:hover{
            background: #fff;
            color: #09CF11;
        }
    }
} input[readOnly]{
    background: #ccc;
}