.directsupport form .form-group{
    padding-bottom: 0px;
    width: auto;
    margin-left: 30px;
}
.directsupport form .form-group input{
    padding-left: 15px;
    height: 40px;
    width: 330px;
}
.directsupport form .seachbtn{
    white-space: nowrap; 
    background: #017A08; 
    border: 1px solid #017A08;
    color: #fff;
    cursor: pointer;
    outline: none !important;
    transition: 0.3s;
    border-radius: 8px;
    padding: 10px 15px;
    margin-left: 30px;
    
}
.directsupport form .seachbtn:hover{
    color: #017A08;
    background: #fff;
}
.directsupport form{display: flex;
    align-items: center;
    justify-content: flex-end;
margin-bottom: 20px;}
    .directsupport form label{
        white-space: nowrap;
        font-size: 22px;
        color: #000;
    }
  .directsupport  .table{
        margin-bottom: 20px;
    }
    .tableblock{
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid #017A08;
        .tableblock-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #017A08;
            padding: 10px 20px;
            h2{
                margin: 0px;
                color: #fff;
                font-size: 16px;
            }
            a{
                display: inline-block;
                font-size: 14px;
                border-radius: 6px;
                border: 1px solid #fff;
                padding: 10px 15px;
                color: #fff;
                text-decoration: none !important;
                i{
                    margin: 0px !important;
                }
            }
        }
    }
    .tableBlockbody{
        padding: 20px;
        p{
            margin: 0px;
            color: #000;
            font-size: 14px;
        text-align: center;
    }
    }