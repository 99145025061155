.hading{
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
    color: #000;
    margin-bottom: 25px;
}
.react-tabs .react-tabs__tab-list{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: center;
}


.card  ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: center;
}
.card  ul li{
    font-size: 14px;
    color: #333;
    display: inline-block;
    padding: 10px 25px;
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.card  ul li:hover{
    background: #017A08;
    color: #fff;
}
.card  ul li.react-tabs__tab--selected{
    background: #017A08;
    color: #fff;
}
.tabcontent{
    position: relative;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
}

.mydocument .table tbody tr td{
   border-bottom: 1px solid #ccc;
}

.table tbody tr td p{
    margin: 0px;
    color: #333;
    font-size: 14px;
}
.grdlabel {
    font-weight: 600;
    display: inline-block;
    font-size: 14px;
    color: #000;
    border-radius: 5px;
    background-image: linear-gradient(to right, #0DFE8A, #00DB09);
    padding: 6px 10px;
    cursor: pointer;
    border: 1px solid #00DB09;
    transition: 0.3s;
}
.grdlabel:hover{
    background: #fff;
    color: #00DB09;
}
.tabcontent .szh-accordion__item{
 border: 1px solid #017A08;
 border-radius: 10px;
 overflow: hidden;
 margin-bottom: 15px;
}
.tabcontent .szh-accordion__item button{
    border: 0px;
    color: #333333;
    width: 100%;
    background: rgb(0 65 4 / 11%);
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px;
    position: relative;
}
.tabcontent .szh-accordion__item button::before{
    position: absolute;
    content: "";
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    right: 15px;
    transform: rotate(-45deg);
    transition: 0.3s;
    cursor: pointer;
}
.szh-accordion__item-content{
    padding: 15px;
    position: relative;
}
.productlist tr th,
.productlist tr td{
    text-align: left;
}
.productlist tbody tr:last-child td{
    border-bottom: 0px;
}
.react-tabs .react-tabs__tab-list{
    margin-left: 19px;
}
.szh-accordion__item--expanded{
    border: 1px solid #ccc !important;
}
.szh-accordion__item--expanded button{
    background: #333333 !important;
    color: #fff !important;
}
.szh-accordion__item--expanded button::before{
    transform: rotate(133deg) !important;
    border-color: #fff !important;
}