.downlinesearch form .form-group{
    padding-bottom: 0px;
    width: auto;
    margin-left: 30px;
}
.downlinesearch form .form-group input{
    padding-left: 15px;
    height: 40px;
    width: 330px;
}
.downlinesearch form .seachbtn{
    white-space: nowrap; 
    background: #017A08; 
    border: 1px solid #017A08;
    color: #fff;
    cursor: pointer;
    outline: none !important;
    transition: 0.3s;
    border-radius: 8px;
    padding: 10px 15px;
    margin-left: 30px;
    
}
.downlinesearch form .seachbtn:hover{
    color: #017A08;
    background: #fff;
}
.downlinesearch form{display: flex;
    align-items: center;
    justify-content: flex-end;
margin-bottom: 20px;}
    .downlinesearch form label{
        white-space: nowrap;
        font-size: 22px;
        color: #000;
    }
    .cancelBtn {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 10px;
        outline: 0 !important;
        cursor: pointer;
        transition: 0.3s;
        background: #F42323;
        border: 1px solid #F42323;
        margin-left: 10px;
    }