.note{
    background: rgb(0 219 9 / 10%);
    border: 1px solid #00DB09;
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    color: #038408;
}
.note p{
    font-size: 13px;
    margin: 0px;
    margin-bottom: 5px;
    color: #038408;
}
.note p b{
    font-weight: 500;
    display: block;
}
.card span.blockspan{
    display: block;
}
.card p{
    margin-bottom: 8px;
}


.uploadBtn input{
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    opacity: 0;
}
.note_pendding {
    background: rgb(0 219 9 / 10%);
    border: 1px solid #fcd64d;
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    color: #fba300;
}