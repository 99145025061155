/*.mypurchase .note{
    margin-top: 0px;
    margin-bottom: 15px;
}
.mypurchase .card .table tr th,
.mypurchase .card .table tr td{
    text-align: left;
}
.mypurchase .card .table tr td a i{
    margin: 0px;
    color: #888;
}
.mypurchase .card .table tr td a:hover i{
    color: #017A08;
}*/

.productoffer .note{
    margin-top: 0px;
    margin-bottom: 15px;
    background: rgb(0 219 9 / 10%);
    border: 1px solid red;
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    color: red;
    text-align: center;
}

.productoffer .card h3{
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 10px;
}
.productoffer .card p{
    margin: 0px;
    color: #000;
    font-size: 14px;
}
.productoffer .card h3 a{
    display: inline-block;
    cursor: pointer;
}