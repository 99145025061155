.hading{
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
    color: #000;
    margin-bottom: 25px;
}
.react-tabs .react-tabs__tab-list{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: center;
}

.card {
        position: relative;
        border: 1px solid rgb(204 204 204 / 49%);
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(204 204 204 / 27%);
        margin-bottom: 25px;
}
.card  ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: center;
}
.card  ul li{
    font-size: 14px;
    color: #333;
    display: inline-block;
    padding: 10px 25px;
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.card  ul li:hover{
    background: #017A08;
    color: #fff;
}
.card  ul li.react-tabs__tab--selected{
    background: #017A08;
    color: #fff;
}
.tabcontent{
    position: relative;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
}
.table{
    width: 100%;
    border-spacing: 0px;
    border: 1px solid #017A08;
    overflow: hidden;
    border-radius: 10px;

}
.table thead th{
    background: #017A08;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 15px;
}

.table td{
    padding: 12px 10px;
    color: #000;
    font-size: 14px;
    text-align: center;
}
.table tbody tr td{
   border-bottom: 1px solid #ccc;
}
.table tbody tr td:last-child{
    border-right: 0px;
}
.table tbody tr:last-child td{
    border-bottom: 0px;
}
.table tbody tr td p{
    margin: 0px;
    color: #333;
    font-size: 14px;
}
.grdlabel {
    font-weight: 600;
    display: inline-block;
    font-size: 14px;
    color: #000;
    border-radius: 5px;
    background-image: linear-gradient(to right, #0DFE8A, #00DB09);
    padding: 6px 10px;
    cursor: pointer;
    border: 1px solid #00DB09;
    transition: 0.3s;
}
.grdlabel:hover{
    background: #fff;
    color: #00DB09;
}
.szh-accordion__item{
    border: 1px solid #ccc;
}