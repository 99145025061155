.flxtd{
    display: flex;
    justify-content: space-between;
}

.flxtd span{
    width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    color: #000;
}
.subheading td {
    background: #333;
    color: #fff;
}

.subheading td .flxtd span{
    color: #fff;
}
.tdAction a{    width: 25px;
    height: 25px;
    display: inline-block;
    background: #004104;
    color: #fff;
    border-radius: 3px;
    padding: 4px;
    cursor: pointer;
    margin-left: 10px;}
    .tdAction a i{
        margin: 0px !important;
    }
    .tdAction a:hover{
        background: #017A08;
        color: #fff;
    }
    .deriectdownline .card ul{
        margin-bottom: 20px;
    }
    .deriectdownline .card ul li{  padding: 0px;
        color: #fff !important;
        font-size: 13px !important;
        border-radius: 0px !important;
        background: transparent !important;
    span{
        color: #fff !important;
        font-size: 13px !important;
        margin:0px !important;
        /*padding: 1px 5px;*/
        border-radius: 5px;
        margin-right: 8px !important;
    }
    }
    .deriectdownline{
        .new{
          background: #333;
        }
        .active{
        background: #09CF11 !important;
        }
        .due{
           background: #EFAF0A;
        }
        .resignerd{
          background: #0BCAE4;
        }
        .expaire{
           background: #F42323;
        }
        .type_li{
            background: #ccc;
            padding: 10px !important;
        }
    }