.headerCom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: #fff;
    padding: 11px 20px;
    box-shadow: 2px 3px 10px rgb(219 215 215);
}
.headerLeft{
    display: flex;
    align-items: center;
}
.headerLeft span{
    display: inline-block;
}
.headerLeft h1{
    margin: 0px;
    font-size: 20px;
    color: #000;
    margin-left: 15px;
    text-transform: capitalize;
}
.headerRight p{
    margin: 0px;
    color: #000000;
    font-size: 14px;
    margin-bottom: 5px;
}
.headerRight p a{
    text-decoration: none !important;
    display: inline-block;
    color: #017A08;
    font-weight: 500;
}
.user{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.userIcon{
    width: 35px;
    height: 35px;
   border-radius: 100%;
   overflow: hidden;
   border: 2px solid #FF7E06;
   margin-right: 10px;
}
.user .userIcon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
.userDetails label{
    display: inline-block;
    margin: 0px;
    color: #000;
    font-weight: 600;
    margin-right: 5px;
}
.userDetails i{
    font-size: 15px;
}
.action{
    position: relative;
    }
    .action ul{
    position: absolute;
    list-style: none;
    padding: 0px;
    margin: 0px;
    background: #fff;
    z-index: 1;
    box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    right: -5px;
    top: 30px;
    }
    .action ul::before{
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    background: #fff;
    box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.10);
    transform: rotate(45deg);
    right: 10px;
    top: -6px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    z-index: -1;
    }
    .action ul li {
    display: block;
    }
    .action ul li a{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    width: 100%;
    padding: 10px 20px;
    transition: 0.3s;
    }
    .action ul li:first-child a{
    border-top-left-radius: 8px;
    border-top-right-radius:8px;
    }
    .action ul li:last-child a{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius:8px;
    }
    .action ul li a:hover{
    background: #017A08;
    color: #fff;
    }
    .action ul li a i{
    font-size: 14px;
    margin-right: 10px;
    }
    .user ul{
      display: none;
      }
      .user ul.open{
      display: block;
      }
     