.product{

    .productlistitem{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        transition: 0.3s;
        padding: 15px 0px;
        border-bottom: 1px solid #ccc;
        .productlistitem-img{
            width: 100px;
            height: 100px;
            border-radius: 10px;
            border: 1px solid #ccc;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .productlistitem-dtls{
            width:calc(100% - 100px);
            padding-left: 20px;
            h3{
                margin: 0px ;
                color: #004104;
                font-size: 16px;
                margin-bottom: 10px;
            }
            p{
                color: #000;
                font-size: 14px;
                margin: 0px;
                margin-bottom: 5px;
            }
            b{
                color: #017905;
                font-size: 14px; 
                font-weight: 600;
            }
        }
        &:hover{
            background: rgb(76 175 80 / 15%);
        }
    }
    .col-md-5{
        width: 41.65%;
    }
    .col-md-7{
        width: 58.31%;
    }
}
.invoice{
    padding-left: 20px;
    img{
        width: 100%;
    }
}
.productlist{
    overflow: auto;
    max-height: 700px;
}

.saleList .productlistitem{
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.productLft{
    display: flex;
    align-items: center;
}
.productRyt{
    text-align: right;
}
.productRyt p{
    font-weight: 600;
    margin: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #017A08;
}
.downloadIvc{
    border: 1px solid #017A08;
    background: rgb(1 122 8 / 8%);
    color: #017A08;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    transition: 0.3s;
}
.downloadIvc:hover{
    background: #004104;
    color: #fff;
}
.saleList form{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 25px;
}
.saleList form  .form-group{
    padding-bottom: 0px;
    width: auto;
}
.saleList form  .form-group input{
    height: 45px;
    width: 300px;
    padding: 10px 15px;
}
.saleList  .seachbtn{
    position: relative;
    border: 1px solid #038408;
    padding: 10px 25px;
    color: #fff;
    background: #038408;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 8px;
}