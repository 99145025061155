.incomeF{
 .table{
    td{
        a{
         color: #09CF11;
         font-size: 14px;
         display: inline-block;
         padding: 8px 10px;
         border: 1px solid #09CF11;
         border-radius: 5px;
         text-decoration: none;
         cursor: pointer;
        transition: 0.3s;
        margin: 0px 5px;
        i{
            margin: 0px !important;
        }
        &:hover{
            background: #09CF11;
            color: #000;
        }
        }
    }
 }
 .cancelBtn{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: 0.3s;
    background: #F42323;
   border: 1px solid #F42323;
   margin-left: 20px;
   &:hover{
      background: #fff;
      color: #F42323;
   }
}
.sucessBtn{
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 10px;
  outline: 0 !important;
  cursor: pointer;
  transition: 0.3s;
  background: #09CF11;
  border: 1px solid #09CF11;
  /*margin-left: 20px;*/
  &:hover{
      background: #fff;
      color: #09CF11;
  }
}
    .product_details_ul{
        display: flex !important;
        flex-direction: column !important;
    }

    .product_details_ul li{
        background: #fff;
        color: #333 !important;
    }

    .product_details_ul li:hover {
        background: #fff;
        color: #333;
    }



}