.menuCom{
    background: rgb(2,122,11);
    background: linear-gradient(176deg, rgba(2,122,11,1) 0%, rgba(48,163,216,1) 35%, rgba(14,34,184,1) 100%);
    position: relative;
    height: 100%;
    width: 100%;
}
nav{
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 30px;
}
.logo{
    width: 150px !important;
    height: 120px !important;
    background: #fff;
    border-radius: 20px;
    overflow: hidden !important;
}
.logo img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
nav ul{
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    padding: 0px;
    margin: 0px;
}
nav ul li{
    margin-bottom: 15px;
}
nav ul li a{
    width: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none !important;
    padding: 10px 20px;
    transition: 0.3s;
    display: inline-block;
}
nav ul li a i{
    font-size: 25px;
    margin-right: 10px;
}
nav ul li a:hover{
    background: #fff;
    color: #017A08;
}
nav ul li a.active{
    background: #fff;
    color: #017A08 !important;
}
.copy{
    font-size: 13px;
    color: #fff;
    text-align: center;
    margin: 0px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
}
.dropdown li a{
   font-size: 14px;
}
.dropdown li{
    margin-bottom: 0px;
}
.menuUl{
    max-height: 600px;
    overflow: auto;
}
#main_li a.active{
    background: #fff;
    color: #017A08;
}
.menuUl::-webkit-scrollbar {
    width: 8px;
    background: #ccc;
    border-radius: 10px;
  }
   

   
  .menuUl::-webkit-scrollbar-thumb {
    width: 8px;
    background: #017A08;
    border-radius: 10px;
  }
  .dropdown{
    display: none;
  }
  .menuUl li.active .dropdown{
    display: flex;
  }
  #submenu{
    display: none;
    }
    #submenu.active{
    display: block;
    color: #fff;
    }
    #submenu li a{
       width: 100%;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      text-decoration: none !important;
      padding: 10px 20px;
      transition: 0.3s;
      display: inline-block;
      padding-left: 45px !important;
    }
    #submenu li a:active{
      color: #017A08;
    }
    #submenu li a:hover{
      background: #fff;
      color: #017A08;
    }