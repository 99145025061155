@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
*{
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    }
    .row{
        display: flex;
        margin:0px -15px;
        flex-wrap: wrap;
    }
    .col-md-4{
        width: 33.33%;
        padding: 0px 15px;
    }
    .col-md-3{
        width: 25%;
        padding: 0px 15px;
    }
    .col-md-2{
        width: 16.666%;
        padding: 0px 15px;
    }
    .col-md-1{
        width: 8.333%;
        padding: 0px 5px;
    }
    .col-md-8{
        width: 66.666%;
        padding: 0px 15px;
    }
    .col-md-12{
        width: 100%;
        padding: 0px 15px;
    }
    .card{
        position: relative;
        border: 1px solid rgb(204 204 204 / 49%);
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(204 204 204 / 27%);
        margin-bottom: 25px;
    }
   
    .table{
        width: 100%;
        border-spacing: 0px;
        border: 1px solid #017A08;
        overflow: hidden;
        border-radius: 10px;

    }
    .table thead th{
        background: #017A08;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 12px 15px;
    }

    .table td{
        padding: 12px 10px;
        color: #000;
        font-size: 14px;
        text-align: center;
    }
    .table tbody tr td{
       border-bottom: 1px solid #ccc;
       border-right: 1px solid #ccc;
    }
    .table tbody tr td:last-child{
        border-right: 0px;
    }
    .table tbody tr:last-child td{
        border-bottom: 0px;
    }
    .modal_custom{
        background: rgb(0 0 0 / 58%);
        position: fixed;
        inset: 0px;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
    }
    .modal_custom.open{
        visibility: visible;
        opacity: 1;
    }
    .uploadDocument{
        text-align: center;
    }
    .uploadDocument-img{
        position: relative;
        width: 200px;
        height: 150px;
        margin: 0 auto;
        max-width: 100%;
        border: 1px dashed #ccc;
        padding: 15px;
        /*background-image: url(./assets/img/upload.png);*/
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
        border-radius: 15px;
    }




    .uploadDocument-img input{
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    .uploadDocument-img img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .modalBody_custom h3{
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 20px;
    }
    .modalBody_custom h3 a{
        font-size: 20px;
        display: inline-block;
        cursor: pointer;
        color: #333;
    }
    .modalBody_custom{
        width: 400px;
        background: #fff;
        padding: 20px;
        position: relative;
        max-width: 100%;
        border-radius: 15px;
        visibility: hidden;
        transform: translate(0px , -100px);
        opacity: 0px;
        transition: 0.3s;
    }
    .modal_custom.open  .modalBody_custom{
        transform: translate(0px , 0px);
        opacity: 1;
        visibility: visible;
    }
    .actioncard{
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .actioncard a{
        display: inline-block;
        font-size: 16px;
        color: 888;
        margin-left: 15px;
    }
    .openModal{
        position: relative;
        border: 1px solid #038408;
        padding: 5px 15px;
        color: #fff;
        background: #038408;
        cursor: pointer;
        border-radius: 8px;
    }
    .uploadBtn{
        position: relative;
        border: 1px solid #038408;
        padding: 10px 25px;
        color: #fff;
        background: #038408;
        cursor: pointer;
        margin-top: 15px;
        border-radius: 8px;
    }

    .idCardDownloadBtn{
        position: relative;
        border: 1px solid #038408;
        padding: 10px 25px;
        color: #fff;
        background: #038408;
        cursor: pointer;
        margin-top: 15px;
        border-radius: 8px;
    }

    /*me*/
    .activityLoader{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffffc4;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
  }
  .ReactModal__Overlay{
      background-color: rgb(0 0 0 / 40%) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
    .ReactModal__Content{
      inset: 0% !important;
      padding: 0px !important;
      overflow: hidden;
      position: relative !important;
      border: 0px !important;
      border-radius: 10px !important;
      width: 500px !important;
      max-width: 100% !important;
    }
  .modal_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #017A08;
      color: #FFF;
      font-size: 16px;
      font-weight: 500;
      margin: 0px;
      padding: 15px 23px;
    }
    .modalBody{
      padding: 20px;
    }
    .form-group{
      position: relative;
      padding-bottom: 20px;
    }
    .form-group label{
      color: #000;
      font-size: 14px;
      display: block;
      margin-bottom: 10px;
    }
    .form-group input{
      width: 100%;
      height: 40px;
      border: 1px solid #CCC;
      width: 100%;
      padding: 10px 15px;
      background: #fff;
      outline: none;
    }
    .form-group select{
      width: 100%;
      height: 40px;
      border: 1px solid #CCC;
      width: 100%;
      padding: 10px 15px;
      background: #fff;
      outline: none;
    }
   
    .form-group button{
      border: 0px;
      width: 45%;
    }
    .form-group button{
      margin-left: 0px;
    }
    .fadebtn{
      color: #017A08;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      background: rgb(220, 254, 192);
      padding: 10px 15px;
      transition: 0.3s;
      position: relative;
      }
      .fadebtn:hover{
      background: #017A08;
      color: #fff;
      }
      .fillbtn{
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      background: #017A08;
      padding: 10px 15px;
      margin-left: 10px;
      transition: 0.3s;
      }
      .fillbtn:hover{
      color: #017A08;
      background: rgb(220, 254, 192)
      }
      .fadebtn_resend{
          color: #017A08;
          font-size: 14px;
          font-weight: 500;
          border-radius: 8px;
          background: rgb(220, 254, 192);
          padding: 10px 15px;
          transition: 0.3s;
          position: relative;
      }
    .otp_input_parent_div{
      justify-content: center;
      display: flex;
  }
  .otp_input_parent_div input{
      width: 35px !important;
      height: 35px;
  }
  .viewBtn{
    cursor: pointer;
  }
  .pagenotfound{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 20px;
  }

  .pagination_div ul{
    margin: 0px;
    list-style: none;
    padding: 0px;
    display: inline-flex;
    border: 1px solid #ccc;
}
.pagination_div{
    text-align: right;
}
.pagination_div ul li{
    display: block;
    border-right: 1px solid #ccc;
}
.pagination_div ul li a{
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #333;
    transition: 0.3s;
}
.pagination_div ul li:last-child{
    border-right: 0px;
}
.pagination_div ul li.previous a{
    background: #fff;
    color: #017A08;
}
.pagination_div ul li.next a{
    background: #fff;
    color: #017A08;
}
.pagination_div ul li a:hover{
    background:#017A08;
    color: #fff;
}
.pagination_div ul li.selected a{
    background:#017A08;
    color: #fff;
}
  /*end me*/