.profile{
    h3{
        color: #004104;
    }
    .form-group{
        position: relative;
        padding-bottom: 30px;
        label{
            display: block;
            font-size: 14px;
            color: #000;
            font-weight: 600;
            margin-bottom: 5px;
        }
        input{
            height: 45px;
            padding-left: 15px;
      
        }
        select{
            height: 45px;
            padding-left: 15px;
            background: #D9D9D9;
        }
    }
    .cancelBtn{
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          padding: 10px 25px;
          border-radius: 10px;
          outline: 0 !important;
          cursor: pointer;
          transition: 0.3s;
          background: #F42323;
         border: 1px solid #F42323;
         &:hover{
            background: #fff;
            color: #F42323;
         }
    }
    .sucessBtn{
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 10px;
        outline: 0 !important;
        cursor: pointer;
        transition: 0.3s;
        background: #09CF11;
        border: 1px solid #09CF11;
        margin-left: 20px;
        &:hover{
            background: #fff;
            color: #09CF11;
        }
    }
} input[readOnly]{
    background: #D9D9D9;
}
.support_card{
    position: relative;
    border: 1px solid rgb(204 204 204 / 49%);
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(204 204 204 / 27%);
    margin-bottom: 25px;
}
.query_list_main{

}

.query_list_main ul{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0;

}

.query_list_main ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 1px solid #004104;
    background: #004104;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}




.query_question{
    width: 100%;
}

.query_question label{
    margin-bottom: 15px;
    font-weight: 600;
    color: #000;
    font-size: 16px;
    display: flex;
}

.query_question_in{

    padding: 20px;
    margin-bottom: 20px;
    background: rgb(0 0 0 / 3%);
}


.query_answer{
    width: 100%;
}

.query_answer label{
    margin-bottom: 15px;
    font-weight: 600;
    color: #000;
    font-size: 16px;
    display: flex;
}




.query_answer_in{
    
    padding: 20px;
    margin-bottom: 20px;
    background: rgb(0 65 4 / 9%);
}