.mypurchase .note{
    margin-top: 0px;
    margin-bottom: 15px;
}
.mypurchase .card .table tr th,
.mypurchase .card .table tr td{
    text-align: left;
}
.mypurchase .card .table tr td a i{
    margin: 0px;
    color: #888;
}
.mypurchase .card .table tr td a:hover i{
    color: #017A08;
}
.mypurchase .sucessBtn{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: 0.3s;
    background: #09CF11;
    border: 1px solid #09CF11;
    /*margin-left: 20px;*/
    &:hover{
        background: #fff;
        color: #09CF11;
    }
}
.mypurchase .cancelBtn{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: 0.3s;
    background: #F42323;
   border: 1px solid #F42323;
   margin-left: 20px;
   &:hover{
      background: #fff;
      color: #F42323;
   }
}