.col-md-6{
    width: 50%;
    padding: 0px 15px;
}
.storeLocate .card p small{
    display: inline-block;
    font-size: 100%;
    color: #004104;
    font-weight: 500;
}
.flxp{
    align-items: center;
    display: flex;
}
.flxp img{
    margin-right: 10px;
}
.phone{
    position: absolute;
    right: 15px;
    top: 15px;
    border: 1px solid #00DB09;
    background: rgb(0 219 9 / 10%);
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
}
.phone a{
    display: flex;
    align-items: center;
    text-decoration: none;
}
.phone a span{
    margin-bottom: 0px;
}
.phone a img{
    margin-right: 8px;
}
.phone a label{
    font-size: 14px;
    color: #00DB09;
    font-weight: 500;
    display: block;
    margin: 0px;
}
.storeLocate form .form-group{
    padding-bottom: 0px;
    width: auto;
    margin-left: 30px;
}
.storeLocate form .form-group select{
    padding-left: 15px;
    height: 40px;
    width: 330px;
}
.storeLocate form .seachbtn{
    white-space: nowrap; 
    background: #017A08; 
    border: 1px solid #017A08;
    color: #fff;
    cursor: pointer;
    outline: none !important;
    transition: 0.3s;
    border-radius: 8px;
    padding: 10px 15px;
    margin-left: 30px;
    
}
.storeLocate form .seachbtn:hover{
    color: #017A08;
    background: #fff;
}
.storeLocate form{display: flex;
    align-items: center;
    justify-content:center;}
    .storeLocate form label{
        white-space: nowrap;
        font-size: 22px;
        color: #000;
    }
.phone a h5{
    color: #000000; 
    margin: 0px;
    font-weight: 500;
}