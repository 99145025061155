.product{

    .productlistitem{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        transition: 0.3s;
        padding: 15px 0px;
        border-bottom: 1px solid #ccc;
        .productlistitem-img{
            width: 100px;
            height: 100px;
            border-radius: 10px;
            border: 1px solid #ccc;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .productlistitem-dtls{
            width:calc(100% - 100px);
            padding-left: 20px;
            h3{
                margin: 0px ;
                color: #004104;
                font-size: 16px;
                margin-bottom: 10px;
            }
            p{
                color: #000;
                font-size: 14px;
                margin: 0px;
                margin-bottom: 5px;
            }
            b{
                color: #017905;
                font-size: 14px; 
                font-weight: 600;
            }
        }
        &:hover{
            background: rgb(76 175 80 / 15%);
        }
    }
    .col-md-5{
        width: 41.65%;
    }
    .col-md-7{
        width: 58.31%;
    }
}
.invoice{
    padding-left: 20px;
    img{
        width: 100%;
    }
}
.productlist{
    overflow: auto;
    max-height: 700px;
}