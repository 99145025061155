.authapper{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.authapper-body{
    width: 1106px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    padding: 30px;
    max-width: 100%;
    background: rgb(2,122,11);
background: linear-gradient(90deg, rgba(2,122,11,1) 0%, rgba(48,163,216,1) 35%, rgba(14,34,184,1) 100%);
display: flex;
flex-wrap: wrap;
}
.authapper-body:before{
     position: absolute;
     content: "";
     background-image: url('../assets/img/formleft.png');
     background-position: left;
     background-repeat: no-repeat;
     width: 397px;
     left: 0px;
     top: 0px;
     bottom: 0px;
}
.authapper-left{
    width: 50%;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.authapper-right{
    width: 50%;
    position: relative;
    background: #fff;
    text-align: center;
    border-radius: 20px;
    padding: 50px 30px;
}
.logo{
    width: 200px;
    height: 150px;
    margin: 0 auto;
    background: #fff;
    border-radius: 15px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 25px;
}
.logo img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.authapper-left p{
    text-align: center;
    width: 379px;
    margin: 0 auto;
    color: #fff;
    max-width: 100%;
    line-height: 23px;
    font-size: 14px;
    margin-bottom: 25px;
}
.authapper-left p a{
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.authapper-left p a i{
    font-size: 20px;
    margin-right: 8px;
}
.copyright{
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   color: #fff;
   font-size: 13px;
   bottom: 0px;
   white-space: nowrap;
}
.authapper-right h1{
    font-size: 40px;
    color: #000;
font-weight: 300;
margin: 0px;
text-transform: capitalize;
margin-bottom: 5px;
}
.authapper-right h2{
    font-size: 35px;
    color: #000;
    margin: 0px;
    text-transform: capitalize;
    margin-bottom: 30px;
    font-weight: 400;
}
.form-group{
    position: relative;
    width: 100%;
    padding-bottom: 25px;
}
.form-group img{
    position: absolute;
    width: 18px;
    left: 15px;
    top: 20px;
}
.form-group input{
    width: 100%;
    border-radius: 7px;
    border: 1px solid #CCCCCC;
    padding: 10px 15px;
    outline: none !important;
    height: 58px;
    padding-left: 45px;
}
.themeBtn{
    width: 100%; 
    height: 58px;
    background: #017A08;
    border-radius: 7px;
    border: 1px solid #017A08;
    transition: 0.3s;
    cursor: pointer;
    outline: none !important;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px;
}
.themeBtn:hover{
    background: #fff;
    color: #017A08;
}
.formbtm{
    text-align: center;
}
.formbtm a{
    text-decoration: none;
    font-size: 14px;
    color: #017A08;
    display: inline-block;
    text-transform: capitalize;
    transition: 0.3s;
}
.formbtm a:hover{
    color: #333;  
}
.formbtm p{
    font-size: 14px;
    color: #333;
}