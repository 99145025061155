.myincentive{
  .card ul{
    margin-bottom: 20px;
}
.card ul li{  padding: 0px;
    color: #fff !important;
    font-size: 13px !important;
    border-radius: 0px !important;
    background: transparent !important;
    span{
        color: #fff !important;
        font-size: 13px !important;
        margin:0px !important;
        /*padding: 1px 5px;*/
        border-radius: 5px;
        margin-right: 8px !important;
    }
}
 .new{
    background: #333;
  }
  .active{
  background: #09CF11 !important;
  }
  .due{
     background: #EFAF0A;
  }
  .resignerd{
    background: #0BCAE4;
  }
  .expaire{
     background: #F42323;
  }
  .type_li{
      background: #ccc;
      padding: 10px !important;
  }
}
