.product{

    .productlistitem{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        transition: 0.3s;
        padding: 15px 0px;
        border-bottom: 1px solid #ccc;
        .productlistitem-img{
            width: 100px;
            height: 100px;
            border-radius: 10px;
            border: 1px solid #ccc;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .productlistitem-dtls{
            width:calc(100% - 100px);
            padding-left: 20px;
            h3{
                margin: 0px ;
                color: #004104;
                font-size: 16px;
                margin-bottom: 10px;
            }
            p{
                color: #000;
                font-size: 14px;
                margin: 0px;
                margin-bottom: 5px;
            }
            b{
                color: #017905;
                font-size: 14px; 
                font-weight: 600;
            }
        }
        &:hover{
            background: rgb(76 175 80 / 15%);
        }
    }
    .col-md-5{
        width: 41.65%;
    }
    .col-md-7{
        width: 58.31%;
    }
}
.invoice{
    padding-left: 20px;
    img{
        width: 100%;
    }
}
.productlist{
    overflow: auto;
    max-height: 700px;
}
.saleForm .col-md-7{
    padding: 0px 15px;
}
.saleForm .col-md-5{
    padding: 0px 15px;
}
.saleForm  input{
    height: 45px;
    padding: 10px 15px;
}
.product_select  select{
    height: 45px;
    padding: 10px 15px;
}
.saleForm{
    padding: 0px 20px;
}
.saleForm .uploadBtn{
    text-transform: uppercase;
    width: 100%;
}
.no_record{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
}
.addProduct_more{
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    padding: 10px 15px;
    transition: 0.3s;
    cursor: pointer;
}
.search_select ul{
    display: block !important;
}

.search_select ul li {
    display: block !important;
}




.search_select ul li:hover {
    background: #fff !important;
    color: #000 !important;
    display: block !important;
}


.search_select  .select-search-container{
    width: 100% !important;
    margin-bottom: 15px;
    border-radius: 5px;
}


.search_select  .select-search-input {
    border: 2px solid #CCCCCC !important;
    border-radius: 5px !important;
}

.search_select  .select-search-select {
    border: 2px solid #CCCCCC !important;
}
.invoice{
    padding-left: 20px;
}
.invoice img{
    width: 100%;
}