.hading{
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
    color: #000;
    margin-bottom: 25px;
}




.card  ul li:hover{
    background: #017A08;
    color: #fff;
}
.card  ul li.react-tabs__tab--selected{
    background: #017A08;
    color: #fff;
}
.tabcontent{
    position: relative;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
}

