.card h2{
    font-size: 20px;   
    color: #000;
    margin: 0px;
    margin-bottom: 10px;
}
.card span{
    font-size: 14px;
    transition: 0.3s;
    color: #017A08;
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}
.card  p{
    margin: 0px;
    color: #000;
    font-size: 14px;
    margin-bottom: 8px;
}
.card  p:last-child{
    margin-bottom: 0px;
}
/*.viewBtn{
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
}*/
.viewBtn i{
    color: #888;
}

.xlm .modalBody_custom{
    width: 800px !important;
}
.xlm  .uploadBtn{
    margin-bottom: 20px;
}
.xlm  .form-group input{
    padding: 10px 15px;
    font-size: 14px;
    height: 45px;
}
.xlm .col-12{
    width: 100%;
    padding: 0px 15px;
}
.xlm .col-6{
    width: 50%;
    padding: 0px 15px;
}
.xlm .col-4{
    width: 33.33%;
    padding: 0px 15px;
}
.xlm .cancelBtn {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: 0.3s;
    background: #F42323;
    border: 1px solid #F42323;
}
.xlm .sucessBtn {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: 0.3s;
    background: #09CF11;
    border: 1px solid #09CF11;
    margin-left: 20px;
}

.note_pendding {
    background: rgb(0 219 9 / 10%);
    border: 1px solid #fcd64d;
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    color: #fba300;
}
.uploadePic_bank{
    width: 250px;
    height: 250px;
    overflow: hidden;
    border-radius: 5%;
    border: 2px solid #09CF11;
    margin-bottom: 20px;
}
.uploadePic_bank img{width: 100%;
    height: 100%;
    object-fit: cover;}
.photoUpload_bank{
    display: flex;
    align-items: center;
}
.form-group select {
    width: 100%;
    border-radius: 7px;
    border: 1px solid #CCCCCC;
    padding: 10px 15px;
    outline: none !important;
    height: 46px !important;
    padding-left: 15px !important;
}