.card h2{
      font-size: 20px;   
      color: #000;
      margin: 0px;
      margin-bottom: 10px;
}
.grdlabel{
    font-weight: 600;
    display: inline-block;
    font-size: 14px;
    color: #000;
    border-radius: 5px;
    background-image: linear-gradient(to right, #0DFE8A, #00DB09);
    padding: 3px 10px;
}
.card .user{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.user .usericon{
    width: 58px;
    height: 58px;
    border-radius: 100%;
    overflow: hidden;
margin-right: 10px;
border: 3px solid #FF7E06;
}
.user .usericon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user label{
    margin: 0px;
    display: inline-block;
    color: #000;
    font-weight: 500;
    font-size: 20px;
}
.card p{
    margin: 0px;
    color: #000;
    font-size: 14px;
    margin-bottom: 14px;
}
.card p label{
    margin: 0px;
    font-weight: 600;
}
.cardIcon{
    position: absolute;
    right: 12px;
    top: 12px;
}
.card p span{
    font-weight: 500;
    color: #00DB09;
}
.card a{
    font-size: 14px;
    transition: 0.3s;
    color: #017A08;
}
.card a i{
    margin-left: 0px;
    transition: 0.3s;
}
.card a:hover{
    color: #000;
}
.card a:hover i{
    margin-left: 10px;
}